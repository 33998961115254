import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalAcompanhamentosHoldFormCadastro.module.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormHold from "@page/Logged/Acompanhamentos/FormHold/FormHold";

const PortalAcompanhamentosHoldFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
		stateAcompanhamento,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		holds: stateAcompanhamento.holds || [],
		tracking_verssels_id: stateAcompanhamento.id,
		operation: stateAcompanhamento?.complement?.operation,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			dados: stateDados.holds,
			tracking_verssels_id: stateDados.tracking_verssels_id,
		};

		if(camposInvalidos(dadosEnviar, [
			'tracking_verssels_id',
		], {
			tracking_verssels_id: 'Tracking Verssels ID',
		})) return;

		setLoadingCadastrar(true);

		const ret = await api.trackingVessels.holds.insertMany.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Holds atualizados com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Editar holds"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormHold
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Salvar holds',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalAcompanhamentosHoldFormCadastro;