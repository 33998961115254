import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEmpresasFormEdicao.css';

// API
import { api } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import FormEmpresa from "@page/Logged/Empresas/FormEmpresa/FormEmpresa";
import { msgStatus } from "@func/requests";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import Button from "@cpnt/Button/Button";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";

const PortalEmpresasFormEdicao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '',
		email: '',
		phone: '',
		active: 1,
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalConfirmacaoApagar, setShowPortalConfirmacaoApagar] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);
	const [loadingApagar, setLoadingApagar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.companies.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			name: stateDados.name,
			phone: stateDados.phone,
			email: stateDados.email,
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, [
			'name',
			'phone',
			'email',
		])) return;

		setLoadingEditar(true);

		const ret = await api.companies.update.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Empresa atualizada com sucesso.');
			onSuccess({
				ret: ret,
			})
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingEditar(false);
	}

	/*
	** Deletar empresa
	** ================================================== */
	const apagar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagar(true);

		const ret = await api.companies.delete(id);

		if(ret.status === 200) {
			msg.success('Empresa removida com sucesso.');
			onSuccess({
				ret: ret,
			});
			setShowPortalConfirmacaoApagar(false);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingApagar(false);
	}

	return (
		<Portal
			titulo="Editar empresa"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormEmpresa
								onSubmit={editar}
								stateDados={stateDados}
								setStateDados={setStateDados}
								after={
									<div className="form-bottom">
										<Button
											_={{
												value: 'Apagar',
												className: 'btn btn-red',
												type: 'button',
												onClick: e => {
													setShowPortalConfirmacaoApagar(true);
												},
											}}
										/>
										<Button
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classNameWrap="margin-l-auto"
										/>
										<Button
											_={{
												value: 'Salvar dados',
												onClick: editar
											}}
											loading={loadingEditar}
											classNameWrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={showPortalConfirmacaoApagar}
				titulo="Deseja realmente apagar essa empresa?"
				desc={
					<>
						<div>A empresa "<strong>{stateDados.name}</strong>" será removida. Essa ação não poderá ser desfeita. Qualquer dado associado a essa empresa também será removido.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacaoApagar(false)}
				onClickCancelar={() => setShowPortalConfirmacaoApagar(false)}
				onClickConfirmar={apagar}
				loadingConfirmar={loadingApagar}
			/>
		</Portal>
	);
};

export default PortalEmpresasFormEdicao;