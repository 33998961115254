import React, { useEffect, useRef, useState } from 'react';

// css
import c from './News.module.css';

// API
import { api } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";

import { Select } from "@cpnt/Select/Select";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO } from "@config/objetos";

// Func
import { statusDefault } from "@func/components";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";

import PortalNewsFormCadastro from "@cpnt/Portais/PortaisNews/PortalNewsFormCadastro/PortalNewsFormCadastro";
import PortalNewsFormEdicao from "@cpnt/Portais/PortaisNews/PortalNewsFormEdicao/PortalNewsFormEdicao";
import Icone from "@cpnt/Icone/Icone";

import { cssBg } from "@func/css";

import PortalNewsPreview from "@cpnt/Portais/PortaisNews/PortalNewsPreview/PortalNewsPreview";
import { dataApiParaDataMesDiaAno } from "@func/date";

import imgPDF from '@svg/outros/file-pdf.svg';
import imgPlaceholder from './placeholder.jpg';

const News = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		limit: 20,
		page: 1,
		search: '',
		active: '',
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);


	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
		preview: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateNews, setStateNews] = useState([]);
	const [_stateNews, _setStateNews] = useState({});
	const [stateTiposNews, setStateTiposNews] = useState([]);
	const [stateDadosPreview, setStateDadosPreview] = useState({});

	/*
	** Loading
	** ================================================== */
	const [loadingGetNews, setLoadingGetNews] = useState(false);
	const [loadingGetTiposNews, setLoadingGetTiposNews] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getNews();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get News
	** ================================================== */
	const getNews = async () => {
		if(loadingGetNews) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			limit: refFiltrosAtuais.current.limit,
			search: refFiltrosAtuais.current.search,
			active: refFiltrosAtuais.current.active,
			page: refFiltrosAtuais.current.page,
		});

		setLoadingGetNews(true);

		const ret = await api.news.all.get(dadosEnviar);

		_setStateNews(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStateNews(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetNews(false);
	}

	const changeFiltro = (filterName, filterValue) => {
		var page = stateFiltros.page;
		if(filterName !== 'page') { page = 1; } else { page = filterValue; }
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue, page: page });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue, page: page};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-News`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							News
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Input
										label="Pesquisar news"
										_={{
											value: stateFiltros.search,
        									onChange: e => changeFiltro('search', e.target.value)
										}}
										debounce
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Status"
										_={{
											value: stateFiltros.active,
        									onChange: e => changeFiltro('active', e ? e.value : ''),
											options: STATUS_FILTRO,
											isClearable: true,
										}}
										loading={loadingGetTiposNews}
									/>
								</div>
								<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
									<Select
										label="Resultados"
										_={{
											value: stateFiltros.limit,
        									onChange: e => changeFiltro('limit', e.value),
											options: FILTROS_RESULTADOS,
										}}
										wrap="flex-1"
									/>
								</div>
							</form>
						</FiltrosWrap>
						<Button
							_={{
								value: 'Adicionar news',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_stateNews} paginacao={stateFiltros} loading={loadingGetNews} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="col-id">
													ID
												</th>
												<th className={c['col-imagem']}>
													Imagem
												</th>
												<th className="">
													Título
												</th>
												<th className="">
													Descrição
												</th>
												<th className="col-min">
													PDF
												</th>
												<th className="col-data">
													Publicação
												</th>
												<th className="col-min-3">
													Status
												</th>
												<th className="col-btn">
													Editar
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetNews}
												itens={stateNews}
												request={_stateNews}
												cpnt="PlaceholderNews"
												qtdColunas={8}
											>
												{stateNews.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">
																	{val.id}
																</div>
															</td>
															<td data-titulo="Imagem">
																<div className="td-wrap">
																	<div className={`${c['img-thumb-list']} bg`} style={cssBg(val.photo ? val.photo : imgPlaceholder)}></div>
																</div>
															</td>
															<td data-titulo="Título">
																<div className="td-wrap">
																	{val.title}
																</div>
															</td>
															<td data-titulo="Descrição">
																<div className="td-wrap">
																	{val.text && typeof(val.text) === 'string' ? `${val.text.substring(0, 150)}...` : '-'}
																</div>
															</td>
															<td data-titulo="PDF">
																<div className={`td-wrap align-center ${c['doc-btn']}`}>
																	{val.doc ? <a href={`${val.doc}`} target="_blank" rel="noreferrer"><Icone img={imgPDF} cor="#E74C3C" /></a> : '-'}
																</div>
															</td>
															<td data-titulo="Data">
																<div className="td-wrap">
																	{dataApiParaDataMesDiaAno(val.created_at)}
																</div>
															</td>
															<td data-titulo="Status" className="relative">
																<div className="td-wrap ">
																	<div className={`td-desc-alt ${val.active ? '' : 'color-red'}`}>{statusDefault(val.active)}</div>
																</div>
															</td>
															<td data-titulo="Editar" className="">
																<Button
																	_={{
																		value: 'Editar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				edicao: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Visualizar" className="">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setStateDadosPreview(val);
																			setPortais({
																				...portais,
																				preview: {
																					show: true,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_stateNews} paginacao={stateFiltros} loading={loadingGetNews} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalNewsFormCadastro
				show={portais.cadastro.show}
				onClose={() => {
					setPortais({...portais, cadastro: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastro: {}});
					getNews();
				}}
				closeOnEsc={false}
			/>

			<PortalNewsFormEdicao
				show={portais.edicao.show}
				id={portais.edicao.id}
				onClose={() => {
					setPortais({...portais, edicao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, edicao: {}});
					getNews();
				}}
				closeOnEsc={false}
			/>

			<PortalNewsPreview
				show={portais.preview.show}
				stateDados={stateDadosPreview}
				onClose={() => {
					setPortais({...portais, preview: {}});
				}}
				closeOnEsc={true}
			/>
		</>
	);
};

export { News };
