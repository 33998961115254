import React from 'react';

// css
import c from './FormHold.module.css';

// Routes
import Button from "@cpnt/Button/Button";
import ButtonX from "@cpnt/ButtonX/ButtonX";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";

const FormHold = props => {

	const {
		onSubmit,
		stateDados,
		setStateDados,
		after,
	} = props;

	const removerBox = (index) => {
		const boxOk = stateDados.holds.filter((item, key) => key !== index);
		setStateDados({
			...stateDados,
			holds: boxOk,
		});
	}

	const setValue = (valor, campo, index) => {
		const dados = stateDados;
		dados.holds[index][campo] = valor;
		setStateDados(dados);
	}

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div>
						<div className={`${c['box-loop']}`}>
							{stateDados.holds && stateDados.holds.length ? (
								stateDados.holds.map((val, key) => {
									return (
										<div key={key} className={`${c['box-item']}`}>
											<div className="flex-1">
												<Input
													label="Hold"
													_={{
														value: val.hold || '',
														onChange: e => setValue(e.target.value, 'hold', key),
													}}
													debounce
												/>
											</div>
											<div className="flex-1">
												<Input
													label="Cargo plan"
													_={{
														value: val.cargo_plan || '',
														onChange: e => setValue(e.target.value, 'cargo_plan', key),
													}}
													debounce
												/>
											</div>
											<div className="flex-1">
												<Input
													label={stateDados?.operation === 'discharged' ? 'Discharged' : 'TTL Loaded'}
													_={{
														value: val.cargo_loaded || '',
														onChange: e => setValue(e.target.value, 'cargo_loaded', key),
													}}
													debounce
												/>
											</div>
											<div className={`${c['btn-x-box']}`}>
												<ButtonX
													_={{
														onClick: () => {
															removerBox(key);
														}
													}}
												/>
											</div>
										</div>
									)
								})
							) : (
								<div className={`${c['msg']}`}>Nenhum item encontrado.</div>
							)}
						</div>

						<div className="flex flex-end margin-t-15">
							<Button
								_={{
									type: 'button',
									value: 'Adicionar campo',
									className: 'btn btn-default btn-min-alt',
									onClick: () => {
										const campos = [
											...stateDados.holds,
											{
												hold: '',
												cargo_plan: '',
												cargo_loaded: '',
											}
										];
										setStateDados({
											...stateDados,
											holds: campos,
										});
									}
								}}
							/>
						</div>

					</div>

				</div>

				{after}
			</form>
		</>
	);
};

export default FormHold;