console.log('05/02/2025 18:55')

/*
** Verifica se está no ambiente de desenvolvimento
** Ambiente de dev exibe ou oculta determinadas
** informações que estão em desenvolvimento
** ================================================== */
export const DEV = process.env.REACT_APP_DEV === 'dev' ? true : false;

/*
** Mesma coisa que o DEV, apenas para identificar
** onde estão sendo feitas validações por ID para dev e prod
** ================================================== */
export const DEV_ID = DEV;

/*
** Configurações
** ================================================== */
export const TITULO_PAGINA_PREFIXO = 'Cargonave - ';
export const TITULO_PAGINA_DEFAULT = 'Cargonave Group - Backoffice';
export const EMAIL_CONTATO = 'cargonave@cargonave.com.br';
export const API_TIMEOUT = 15000; // 1000 = 1 segundo | 600.000 = 10 minutos (envio de vídeo é demorado)

/*
** URLs
** ================================================== */
export const URL = window.location.origin;
export const URL_API = process.env.REACT_APP_URL_BACKEND ? process.env.REACT_APP_URL_BACKEND.replace(/\/$/, '') : 'https://api.cargonavegroup.com';

/*
** Liga / Desliga
** ================================================== */

// Exibe o console.log da requests com os dados e retorno
export const EXIBIR_CONSOLE_LOG_REQUESTS = DEV;

// Exibe o console.log da requests com os dados e retorno
export const EXIBIR_CONSOLE_LOG_DEBUG = DEV;

// Exibe o console.log da requests com os dados e retorno
export const BYPASS_TESTE_MENU = DEV;
